<template>
<v-container>

  <SignUp />

</v-container>
</template>
<script>

import SignUp from '../components/signup/SignUp.vue'

export default {
  metaInfo: {
    title: "Home"
  },
  components: {
    SignUp
  }
};
</script>
