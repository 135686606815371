<template>
<div>
  <template v-if="formdone ==='error'">
    <v-container>
      <v-card flex max-width="650px">
          <v-card-title>
            <h3 v-if="!duplicateCode"><v-icon>mdi-clipboard-edit-outline</v-icon> Sorry! There was an Error! </h3>
            <h3 v-if="duplicateCode"><v-icon>mdi-clipboard-edit-outline</v-icon> Duplicate Email Address! </h3>            
          </v-card-title>
        <v-card-text>
          <div v-if="duplicateCode">
            <h3 class="mb-3">The email address you submitted is already in use!</h3>
            <p>Sorry, that email address has already been submited for electronic invoicing.</p>
            <p>If you feel this is incorrect, please <a href="mailto:SBSNCContracts@sharpusa.com">contact us</a> 
            to ensure our information is accurate.</p>
            <p>THANK YOU!</p>
            </div>
          <div v-if="!duplicateCode">
            <h3 class="mb-3">Electronic Billing Sign Up Error!</h3>
            <p>Sorry, but there was an error processing your request. We apologize for the inconvenience.</p>
            <p>Please <a href="mailto:SBSNCContracts@sharpusa.com">contact us</a> via email to have
                your email added to our electronic billing process.</p>                  
                          <p>THANK YOU!</p>
            </div>
        </v-card-text>
      </v-card>
    </v-container>
  </template>  
  <template v-else-if="formdone === 'completed'">
    <v-container>
      <v-card flex max-width="650px">
          <v-card-title>
            <h3><v-icon>mdi-clipboard-edit-outline</v-icon> Thank You! </h3>
          </v-card-title>
        <v-card-text>
          <h3 class="mb-3">Electronic Billing Sign Up Complete!</h3>
          <p>Thank you for your business and for participating in our electronic billing process.</p>
          <p>Please <a href="mailto:SBSNCContracts@sharpusa.com">contact us</a> if you have any questions.</p>        
        </v-card-text>
      </v-card>
    </v-container>

  </template>
  <template v-else>
    <v-container>
      <v-card flex max-width="650px">
          <v-card-title>
            <h3 class="d-none d-sm-flex"><v-icon>mdi-clipboard-edit-outline</v-icon> Electronic Billing Sign Up</h3>
            <h4 class="d-flex d-sm-none"><v-icon>mdi-clipboard-edit-outline</v-icon> Electronic Billing Sign Up</h4>
          </v-card-title>
        <v-card-text>
          <p>As part of our continuing effort to improve the processing of your contract invoices,
            such as the automation of meter collection using <a href="https://www.sharp-sbs.com/The-Sharp-Experience/Automated-Device-Support-MICAS" target="_blank">MICAS</a>, we are pleased to offer you electronic
            invoicing. </p>
          <p>
            Your invoice format will remain the same but will instead be sent to you via email
            as an attachment in pdf format <v-icon class="small">mdi-file-pdf-outline</v-icon>. 
          </p>
          <p>Please enter your details below to have your account 
            set up for electronic billing.</p>
          <v-form 
            ref="signupform"
            v-on:keyup.enter="handleSubmit"
            @submit.prevent="handleSubmit"
            v-model="signupdisabled"
          >
          <v-text-field
              v-model="companyName"
              prepend-icon="mdi-office-building-outline"
              :rules="[rules.required, rules.max]"
              v-on:keyup.enter="handleSubmit"
              type="text"
              required
              hint="Company Name"
              label="Company Name"
              name="companyName"
            ></v-text-field>
          <v-text-field
              v-model="accountNumber"
              prepend-icon="mdi-card-text-outline"
              v-on:keyup.enter="handleSubmit"
              :rules="[rules.max]"
              type="text"
              required
              hint="Account Number or Equipment ID (optional)"
              label="Account Number"
              name="accountNumber"
            ></v-text-field>        
          <v-text-field
              v-model="email"
              prepend-icon="mdi-email-check-outline"
              v-on:keyup.enter="handleSubmit"
              :rules="[rules.required, rules.email]"
              type="text"
              required
              hint="Billing Email Address"
              label="Billing Email Address"
              name="E-mail"
            ></v-text-field>
          </v-form>
          <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      @click="cancel"
                      large
                    >
                      <v-icon>mdi-cancel</v-icon> Cancel
                    </v-btn>
            <v-btn
                      @click="handleSubmit"
                      :disabled="!signupdisabled"
                      color="success"
                      large
                    >
                      <v-icon>mdi-clipboard-edit-outline</v-icon> Sign Up!
                    </v-btn>
                  
          </v-card-actions>
          <div v-if="processing">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Submitting...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="success"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
          </div>
          <p>Please <a href="mailto:SBSNCContracts@sharpusa.com">contact us</a> if you have any questions.</p>        
        </v-card-text>
      </v-card>
    </v-container>

  </template>
</div>
</template>

<script>
import Axios from "axios";

export default {
  metaInfo: {
    title: "Electronic Billing Sign Up"
  },
  data: () => ({
    signupdisabled: false,
    formdone: "standard",
    duplicateCode: false,
    processing: false,
    rules: {
      required: value => !!value || "This field is required.",
      max: value => {
        return (
          value.length <= 35 ||
          "Please limit your entry to 35 characters or less."
        );
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Please enter a valid email address.";
      }
    },
    companyName: "",
    accountNumber: "",
    email: ""
  }),
  methods:{
    cancel: function() {
      this.companyName = ""
      this.accountNumber = ""
      this.email = ""
      this.$refs.signupform.resetValidation()
    },
    handleSubmit: async function() {
      if(this.$refs.signupform.validate()) {
        this.signupdisabled = true;
        this.processing = true;
        const submitPackage = { 
            companyName: this.companyName,
            accountNumber: this.accountNumber,
            email: this.email
            }

        const result = await Axios.post(
            process.env.VUE_APP_FORM_URL,
            {
              ...submitPackage
            },
            {
            headers: {
              "Content-Type": "application/json"
              }
          }).catch((err) => {
            console.log(err)
            this.formdone = "error"
          });
        if(!result.data.success || result.data === undefined) {
            if(result.data.error === "11000") {
              this.duplicateCode = true
            } else {
              this.duplicateCode = false
            }
            this.formdone = "error"
        } else {  
          this.formdone = "completed"
          this.companyName = ""
          this.accountNumber = ""
          this.email = ""
          this.processing = false
          this.$refs.signupform.resetValidation()
        } 
      }
    }
  },
  created() {
    this.processing = false
    this.formdone = "standard"
  }

};
</script>